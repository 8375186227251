import React from "react";
import {
  Modal,
  Input,
  Space,
  Typography,
  Button,
  Flex,
  Tag,
  message,
  Checkbox,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useCreateScraping } from "../../hooks/user.hook.js";

const { Text } = Typography;

const supportedDomains = [
  "olx.pl",
  "otomoto.pl",
  "otodom.pl",
  "katalog.trojmiasto.pl",
];

const filterList = [
  { label: "Wyklucz dealerów i komisy", value: "privateOnly", portal: "otomoto.pl" },
];

export default function AddComponent({ isOpen, close }) {
  const [url, setUrl] = React.useState("");
  const [filters, setFilters] = React.useState([]);
  const createScraping = useCreateScraping(); // Użycie hooka do mutacji
  const handleAdd = () => {
    if (!url) {
      // Walidacja URL
      message.error("URL nie może być pusty");
      return;
    }

    createScraping.mutate({ url, filters }, {
      onSuccess: () => {
        setUrl(""); // Wyczyść pole URL po sukcesie
        close(); // Zamknij modal
      },
      onError: (error) => {
        console.error("Nie udało się dodać scrapingu:", error);
        message.error(
          error.response?.data.message || "Nie udało się dodać scrapingu"
        );
      },
    });
  };
  return (
    <div>
      <Modal
        title="Dodaj nowy URL"
        open={isOpen}
        onCancel={() => close()}
        width={780}
        footer={
          <Flex gap={20} align="center" justify="space-between">
            <Space>
              <Text type="">Wspierane portale: </Text>
              <Space>
                {supportedDomains.map((domain) => (
                  <Tag key={domain}>{domain}</Tag>
                ))}
              </Space>
            </Space>
            <Space>
              <Button onClick={() => close()}>Anuluj</Button>
              <Button
                type="primary"
                onClick={handleAdd}
                loading={createScraping.isLoading}
              >
                Dodaj
              </Button>
            </Space>
          </Flex>
        }
      >
        <Input
          size="large"
          addonBefore={<LinkOutlined />}
          placeholder="https://"
          allowClear
          value={url}
          className="mt-2"
          onChange={(e) => setUrl(e.target.value)}
          onPressEnter={handleAdd}
        />
        <Checkbox.Group
              options={filterList.filter((filter) => url.includes(filter.portal))}
              value={filters}
              onChange={(filters) => setFilters(filters)}
              className="my-3"
            />
      </Modal>
    </div>
  );
}
