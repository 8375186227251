import { useState } from "react";
import {
  Layout,
  Typography,
  Space,
  Button,
  Badge,
  Progress,
  Flex,
  Tooltip,
} from "antd";
import { UserOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { useGetUser } from "../../hooks/user.hook";
import { useLogout } from "../../hooks/auth.hook";

const { Header } = Layout;
const { Text } = Typography;

export default function HeaderComponent() {
  const { data: user, isLoading, isError, error } = useGetUser(); // Użycie hooka
  const { mutate: logout } = useLogout();

  return (
    <Header
      style={{
        background: "#fff",
        borderBottom: "1px solid #f4f4f4",
        height: "unset",
      }}
    >
      <div className="w-[925px] m-auto flex justify-between">
        <Flex gap={20} align="center">
          <Flex gap={20} align="center">
            {!isLoading && (
              <Badge
                status={user.active ? "success" : "error"}
                text={user.active ? "Konto aktywne" : "Konto nieaktywne"}
              />
            )}
            {!isLoading && (
              <>
                <Space>
                  <Text type="secondary">Zuycie planu:</Text>
                  <Text>
                    {user.usage} / {user.limit}
                  </Text>
                </Space>
                <Progress
                  className="w-[200px] mr-4"
                  percent={((user.usage / user.limit) * 100).toFixed(1)}
                />
                <Space>
                  <Text type="secondary">Wygasa:</Text>
                  <Text>
                    {user.nextPaymentDate}
                  </Text>
                </Space>
              </>
            )}
          </Flex>
        </Flex>
        <Space>
          <Text>{user?.username}</Text>
          <Tooltip title="Wyloguj się">
            <Button
              icon={<LogoutOutlined />}
              onClick={() => {
                logout();
                window.location.reload();
              }}
            />
          </Tooltip>
        </Space>
      </div>
    </Header>
  );
}
