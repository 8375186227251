import { Table } from "antd";

export default function TableComponent({
  records,
  loading,
  activeFields,
  hideDuplicatePhones,
}) {

  // let allKeys = Array.from(new Set(records.flatMap(Object.keys)));
  // Initialize an ordered list of keys
  // let orderedKeys = [];

  // // Conditionally add 'images' and 'title' if they exist
  // if (allKeys.includes('images')) {
  //   orderedKeys.push('images');
  // }
  // if (allKeys.includes('title')) {
  //   orderedKeys.push('title');
  // }

  // // Add remaining keys, excluding 'images' and 'title'
  // orderedKeys = orderedKeys.concat(allKeys.filter(key => key !== 'images' && key !== 'title'));

  if (hideDuplicatePhones) {
    const phoneCounts = records.reduce((acc, record) => {
      acc[record.phone] = (acc[record.phone] || 0) + 1;
      return acc;
    }, {});
    records = records.filter(record => phoneCounts[record.phone] === 1);
  }

  return (
    <Table
      dataSource={activeFields.length > 0 ? records : []}
      size="small"
      pagination={{ pageSize: 100 }}
      scroll={{
        x: 2000,
      }}
      loading={loading}
      tableLayout="fixed"
      columns={activeFields.map((key) => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key: key,
          width: 120,
          // textWrap: 'word-break',
          ellipsis: true,
          render: (text, record) => {
            if (key === "images") {
              return JSON.stringify(text);
            }
            return text;
          },
        };
      })}
    />
  );
}
